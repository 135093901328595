.btn-primary {
    --bs-btn-color: white;
    --bs-btn-hover-color: white;
}

.btn-outline-primary {
    --bs-btn-hover-color: white;
    --bs-btn-active-color: white;
}

.modal-title {
    font-weight: bold;
    font-size: 18px;
}

.modal-footer {
    background-color: $admin-bg;
    padding: 15px;
    margin-top: auto;
}

$modal-padding-x: 1rem;

.modal-body {
    padding: 1rem 0;

    h4,
    p {
        padding: 0 $modal-padding-x;
    }

    label {
        width: 100%;
        padding: 0.5rem $modal-padding-x;
        margin: 0.5rem 0;
        background: $admin-bg;
    }

    .input-wrapper,
    .wrapper {
        padding: 0 $modal-padding-x;
    }
}