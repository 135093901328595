.not-found {
    min-width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    color: white;
    background: #101010;

    .main {
        z-index: 10;
    }
}


.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0; // Push the background behind the content
}

.rotating-image {
    position: absolute;
    top: -50vh;
    left: -50vw;
    width: 200%;
    height: 200%;
    object-fit: cover;
    animation: rotateImage 200s linear infinite;
    opacity: 0;
    // animate opacity
    transition: all 2s ease-in-out;
}

@keyframes rotateImage {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}