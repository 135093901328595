$primary: #116dff;
$btn-border-radius: 999999px;
$btn-fonr-size: 14px;
$admin-bg: #ECEFF3;

@import 'bootstrap/scss/bootstrap.scss';

@import './styles/bootstrap_fix';
@import './styles/dashboard';
@import './styles/not_found';

#root,
html,
body {
    height: 100%;
    background-color: unset;
}

h4 {
    font-size: 18px;
}

.text-muted {
    font-size: small;
    color: #999;
}

.text-primary {
    color: $primary
}

.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-1 {
    flex: 1;
}

.center-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

td.center {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.plain {
    a {
        color: unset;
        text-decoration: none;
    }
}

p {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}