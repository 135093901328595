.new-shortlink {
    transition: all 0.4s ease-in-out;
    margin-top: 0;
    padding: 0 2em;
    max-height: 0px;
    overflow: hidden;

    &.show {
        max-height: 1000px;
        margin-top: 1em;
        padding: 2em;
    }
}

td {
    opacity: 1;
    transition-property: color, opacity, font-weight;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

td.new,
tr.new {
    @extend .text-primary;
    --bs-table-color-state: $primary;
    font-weight: bold;
}


tr.muted {
    td {
        opacity: 0.5;
    }
}

tr.operation-open {
    td {
        border-bottom-width: 0;
    }
}

.operation {
    td {
        padding: 20px 0;

    }
}

.custom-tooltip {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}